import PropTypes from 'prop-types'
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link, Box, Text } from 'theme-ui'
import { Meta } from '../components/head'
import { Layout } from '../components/shell'

const ImpressumPage = () => {
  return (
    <Layout>
      <Meta title="Impressum" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Box sx={{ marginBottom: 6 }}>
          <Link to="/" as={GatsbyLink}>
            Zur Applikation
          </Link>
        </Box>
        <Text mb={3} variant="heading" sx={{ fontSize: 2 }}>
          Impressum
        </Text>
        <Text color="muted">
          <Box>
            <Text as="p">Engimono GmbH</Text>
            <Text as="p">ATU78545013 · FN 586598w · Handelsgericht Wien</Text>
          </Box>
          <Box>
            <Text as="p">Waaggasse 15/1, 1040</Text>
            <Text as="p">Wien, Österreich</Text>
            <Text as="p">+43 676 9372999</Text>
            <Text as="p">office@engimono.io</Text>
          </Box>
          <Text as="p" mt={5}>
            Versicherungsvermittler in der Form Versicherungsagent <br />
            und Berater in Versicherungsangelegenheiten.
          </Text>
        </Text>
      </Box>
      <Text as="p" sx={{ mt: 5, textAlign: 'center' }}>
        © {new Date().getFullYear()} Engimono GmbH
      </Text>
    </Layout>
  )
}

ImpressumPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImpressumPage
